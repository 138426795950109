export const formatUrl = (category, id, shouldAdd = true) => {
  let { location } = window
  const splitChr = location.search.includes('?') ? '&' : '?'
  const searchParams = location.search.split('?')
  if (searchParams[searchParams.length - 1].includes(category)) {
    if (shouldAdd) {
      // add given category to the url
      const params = searchParams[1]
        .split('&')
        .map((el) => (el.includes(category) ? `${category}=${id}` : el))
        .join('&')
      history.pushState(null, null, `?${params}`)
    } else {
      // remove given category from the url
      const params = searchParams[1]
        .split('&')
        .map((e) => !e.startsWith(category) && e)
        .filter(Boolean)
        .join('&')

      const hasParams = params.length > 0

      if (hasParams) {
        history.pushState(null, null, `?${params}`)
      } else {
        // replaces trailing ? or & with nothing
        history.pushState(null, null, `${location.pathname}`)
      }
    }
  } else if (shouldAdd) {
    // add given category to the url
    history.pushState(null, null, `${location}${splitChr}${category}=${id}`)
  }
}

export const qsToPairs = (url) => {
  const arr = url.split('?')[1].split(/&|=/)
  const products = []
  const categories = []
  for (let y = 1, i = 0; i < arr.length; i += 2, y += 2) {
    categories.push(arr[i])
    products.push(arr[y])
  }

  return { productIds: products, productCategories: categories }
}
