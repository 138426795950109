import { useAtom } from 'jotai'
import { useEffect, useLayoutEffect, useState } from 'preact/hooks'

import { extractOutfitFromQuery, segmentDestination } from 'utils'
import { AppModeAtom, AppScope } from 'contexts'
import { initAvoInspector } from 'utils/avoInspector'

import Avo from 'utils/Avo'

import {
  SEPERATES_MODE,
  SUITS_MODE,
  VIEW_MODE,
  APP_MODES,
  MODEL_SKIN,
  LS_MODEL_SELECTION,
  FALLBACK_MODEL,
  MODEL_REGEX,
} from 'data'

const useConfiguration = ({
  groupedData,
  isValidating,
  shouldRevertToDefaults,
}) => {
  const [appMode, setAppMode] = useAtom(AppModeAtom, AppScope)

  const [separateProducts, setInitialProducts] = useState(null)
  const [suitProducts, setInitialSuitProducts] = useState(null)
  const [globalProductSet, setGlobalProductSet] = useState(null)

  const [modelSkin, setModelSkin] = useState(
    localStorage.getItem(LS_MODEL_SELECTION) || FALLBACK_MODEL
  )

  const handleConfiguration = () => {
    // update the localstorage with the passed skin if it's different
    const updateModelLayer = (params) => {
      const passedSkin = params.get(MODEL_SKIN)
      const currentSkin = localStorage.getItem(LS_MODEL_SELECTION)

      if (!passedSkin) return

      // update the model skin if it's different
      if (passedSkin !== currentSkin) {
        localStorage.setItem(LS_MODEL_SELECTION, passedSkin)
      }

      return passedSkin
    }

    const getViewMode = () => {
      if (!params.has(VIEW_MODE)) return SEPERATES_MODE

      const userInput = params.get(VIEW_MODE)
      const isValidViewMode = APP_MODES.includes(userInput)

      if (!isValidViewMode) return SEPERATES_MODE
      if (isValidViewMode) return userInput
    }

    const getModelSkin = () => {
      const fallback = FALLBACK_MODEL

      if (!params.has(MODEL_SKIN)) return fallback

      const userInput = params.get(MODEL_SKIN)
      const isValidModelSkin = MODEL_REGEX.test(userInput)

      if (!isValidModelSkin) return fallback

      return updateModelLayer(params)
    }

    // create URLSearchParams object
    const params = new URLSearchParams(
      decodeURIComponent(window.location.search)
    )

    // get the view mode from the query, default to separates mode
    const initialViewMode = getViewMode()

    // get the model skin from the query or localstorage
    const initialModelSkin = getModelSkin()

    setAppMode(initialViewMode)
    setModelSkin(initialModelSkin)
  }

  const handleProducts = ({
    isNavigatingBack,
    hasInitialData,
    hasProductsInQuery,
    query,
    shouldRevertToDefaults,
  }) => {
    if (shouldRevertToDefaults) {
      const fallbackLook = groupedData?.separatesInJSON
        ? groupedData.initialProducts
        : groupedData.defaultProducts

      const fallbackSuitLook = groupedData?.suitsInJSON
        ? groupedData.initialSuitProducts
        : groupedData.defaultSuitProducts

      setInitialProducts(fallbackLook)
      setInitialSuitProducts(fallbackSuitLook)

      return
    }

    // has no products in the JSON & query
    if (!hasInitialData && !hasProductsInQuery) {
      const fallbackLook = groupedData?.separatesInJSON
        ? groupedData.initialProducts
        : groupedData.defaultProducts

      const fallbackSuitLook = groupedData?.suitsInJSON
        ? groupedData.initialSuitProducts
        : groupedData.defaultSuitProducts

      setInitialProducts(fallbackLook)
      setInitialSuitProducts(fallbackSuitLook)

      return
    }

    // has products in the JSON
    if (hasInitialData && !hasProductsInQuery) {
      setInitialProducts(groupedData.initialProducts)
      setInitialSuitProducts(groupedData.initialSuitProducts)

      return
    }

    // has no products in the JSON, but has products in the query
    if (!hasInitialData && hasProductsInQuery) {
      const { seperates, suit } = extractOutfitFromQuery(
        query,
        groupedData.OCAPIData,
        groupedData
      )

      setInitialProducts(seperates)
      setInitialSuitProducts(suit)

      return
    }

    // has products in the JSON, grab from the query
    if (hasInitialData && hasProductsInQuery) {
      const { seperates, suit } = extractOutfitFromQuery(
        query,
        groupedData.OCAPIData,
        groupedData
      )

      setInitialProducts(seperates)
      setInitialSuitProducts(suit)

      return
    }

    // has a navigation back event
    if (isNavigatingBack) {
      const { seperates, suit } = extractOutfitFromQuery(
        query,
        groupedData.OCAPIData,
        groupedData
      )

      setInitialProducts(seperates)
      setInitialSuitProducts(suit)

      return
    }
  }

  useEffect(() => {
    handleConfiguration()
  }, [modelSkin])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        // tracking
        Avo.initAvo(
          {
            env: process.env.ENVIRONMENT,
            webDebugger: !['PRODUCTION'].includes(process.env.ENVIRONMENT),
            webDebuggerOptions: {
              position: Avo.WebDebuggerPosition.TopLeft({ top: 20, left: 20 }),
            },
          },
          null,
          null,
          segmentDestination
        )

        // Initialize Avo Inspector (lazy-loaded)
        initAvoInspector().then((inspector) => {
          if (inspector) {
            console.log('Avo Inspector initialized')
            // Use inspector here if needed
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useLayoutEffect(() => {
    // fetch not ready, early return
    if (isValidating) return

    const isNavigatingBack = ['back_forward'].includes(
      performance?.getEntriesByType('navigation')[0]?.type
    )

    const hasInitialData =
      groupedData?.separatesInJSON || groupedData?.suitsInJSON

    const query = decodeURIComponent(window.location.search)
    const params = new URLSearchParams(query)

    const hasProductsInQuery =
      params.has(SEPERATES_MODE) || params.has(SUITS_MODE)

    // handle products
    handleProducts({
      isNavigatingBack,
      hasInitialData,
      hasProductsInQuery,
      query,
      shouldRevertToDefaults,
    })

    setGlobalProductSet(groupedData.OCAPIData)
  }, [isValidating])

  const dataset = {
    ...groupedData,
    OCAPIData: globalProductSet,
    separateProducts,
    suitProducts,
    modelSkin,
    setModelSkin,
    appMode,
    setAppMode,
    setGlobalProductSet,
  }

  return dataset
}

export default useConfiguration
